.gform_wrapper {
    .gform_heading {
        p {
            @include media-breakpoint-down(sm) {
                font-size: rem-val(18);
            }
        }
    }
    form {
        .gform_body {
            .gform_fields {
                .gfield {
                    label,
                    legend {
                        font-family: "Poppins";
                        font-weight: 100;
                        font-size: rem-val(18);
                        color: $dark;
                        @include media-breakpoint-down(sm) { 
                            font-size: 1rem;
                        }
                    }
                    .ginput_container_text, .ginput_container_email {
                        input {
                            border: 0.5px solid $dark;
                            max-width: 400px;
                            @include media-breakpoint-down(xxl) {
                                max-width: 100%;
                            }
                        }
                    }
                    .ginput_container_checkbox {
                        .gfield_checkbox {
                            display: flex;
                            flex-wrap: wrap;
                            .gchoice {
                                flex: 0 0 50%;
                                margin: rem-val(5) 0;
                                label {
                                    font-family: "Poppins";
                                    font-weight: 100;
                                    font-size: rem-val(21);
                                    color: $dark;
                                    line-height: 32px;
                                    margin-left: 10px;
                                }
                                input {
                                    transform: scale(1.9);
                                    padding: 10px;
                                }
                                @include media-breakpoint-down(sm) {
                                    label {
                                        font-size: 1rem;
                                    }
                                    input {
                                        transform: scale(1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
