@for $i from 1 through 9 {
	$unit: $i*100;
	.fw-#{$unit} {
		font-weight: $unit;
	}
	.fs-#{$i} {
		$size: $i+rem;
		font-size: $size !important;
	}
	$start: $i - 1;
	$mid: $i - .5;
	.fs-#{$start}-5 {
		$size: $mid+rem;
		font-size: $size !important;
	}
}

@mixin font($size,$lineheight,$fontweight,$transform,$fontstyle) {
	font-size: $size;
	line-height: $lineheight;
	font-weight: $fontweight;
	text-transform: $transform;
	font-style: $fontstyle;
}