&.button_content {
    .button-wrapper {
        .accent-wrapper {
            width: 100%;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: $dark;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
            }
            .accent-button-wrapper {
                padding-right: 2rem;
                background: $white;
                width: fit-content;
                z-index: 11;
                position: relative;
                @each $key, $color in $theme-colors {
                    &.bg-#{$key} {
                        background-color: $color;
                    }
                }
            }
        }
    }
    .modal {
        .modal-dialog {
            max-width: 50vw;
            margin: 0 auto;
            @include media-breakpoint-down(lg) {
                max-width: 90vw;
            }
            @include media-breakpoint-down(md) {
                max-width: 80vw;
            }
            .modal-content {
            }
        }
    }
}