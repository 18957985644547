.is-animated:not(:first-of-type) {
    opacity: 0;
    transform: translateY(35%);
    transition: opacity 0.7s, transform 0.7s;
    &.scrolled-into-view {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.7s, transform 0.7s;
    }
    @include media-breakpoint-down(md) {
        animation-duration: .5s;
    }
}
