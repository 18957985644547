//POPPINS
@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
    display: swap;
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    display: swap;
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    display: swap;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
    display: swap;
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-Italic.ttf") format("truetype");
    display: swap;
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
    display: swap;
    font-weight: 700;
    font-style: italic;
}

//Volkron
@font-face {
    font-family: "Vollkorn";
    src: url("../fonts/Vollkorn/Vollkorn-Light.ttf") format("truetype");
    display: swap;
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Vollkorn";
    src: url("../fonts/Vollkorn/Vollkorn-Regular.ttf") format("truetype");
    display: swap;
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Vollkorn";
    src: url("../fonts/Vollkorn/Vollkorn-Bold.ttf") format("truetype");
    display: swap;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Vollkorn";
    src: url("../fonts/Vollkorn/Vollkorn-LightItalic.ttf") format("truetype");
    display: swap;
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Vollkorn";
    src: url("../fonts/Vollkorn/Vollkorn-Italic.ttf") format("truetype");
    display: swap;
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Vollkorn";
    src: url("../fonts/Vollkorn/Vollkorn-BoldItalic.ttf") format("truetype");
    display: swap;
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Baskerville";
    src: url("../fonts/Baskerville/Baskerville-Italic.ttf") format("truetype");
    display: swap;
    font-weight: 300;
    font-style: italic;
}
