//@footer
footer {
    padding: rem-val(100) rem-val(75);
    img.footer-logo {
        display: block;
        margin-bottom: 2rem;
        max-width: 367px;
    }
    div.blurb {
        font-family: "Poppins";
        font-weight: 100;
        font-size: rem-val(20);
        color: $primary;
        letter-spacing: 0;
        line-height: 43px;
        margin-bottom: rem-val(80);
    }
    .footer-links {
        width: 280px;
        margin: 0 auto;
        @include media-breakpoint-down(xl) {
            width: 100%;
        }
        .footer-item {
            margin-bottom: 1.75rem;
            a {
                font-family: "Poppins";
                font-weight: 700;
                font-size: 20px;
                color: $primary;
                letter-spacing: 2.5px;
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }
    .footer-text-band p {
        color: #262c5d;
        text-align: center;
        font-size: 1rem;
        margin-bottom: 0;
        margin-top: 40px;
    }
    .footer-social-images {
        width: fit-content;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    .footer-communities {
        .footer-item {
            flex: 0 0 50%;
            margin-bottom: 4rem;
            .community {
                .community-title {
                    font-family: "Poppins";
                    font-weight: 700;
                    font-size: 1rem;
                    color: $primary;
                    letter-spacing: 2.5px;
                    text-transform: uppercase;
                    text-decoration: none;
                    margin: 0;
                }
                .address {
                    font-family: "Poppins";
                    font-size: rem-val(18);
                    color: $primary;
                    letter-spacing: 1.5px;
                    margin: 0;
                }
                .phone {
                    font-family: "Poppins";
                    font-size: rem-val(18);
                    color: $primary;
                    letter-spacing: 1.5px;
                }
            }
        }
    }
    .divider {
        margin-top: rem-val(90);
        margin-bottom: rem-val(60);
        width: 100%;
        border-bottom: 1px solid $dark;
        &.top {
            margin-top: 0;
            margin-bottom: rem-val(90);
        }
    }

    .social-links {
        a {
            margin: 0 rem-val(7.5);
        }
    }

    .policies {
        .footer-item {
            margin: 0 rem-val(20);
            display: flex;
            flex-direction: column;
            justify-content: center;
            a {
                font-family: "Poppins";
                font-size: 1rem;
                color: $primary;
                letter-spacing: 1.33px;
                text-align: right;
                line-height: 35px;
                text-decoration: none;
            }
        }
    }
    @include media-breakpoint-down(xl) {
        margin-top: 1rem;
        .footer-content {
            margin-bottom: 4rem;
            .blurb {
                margin-bottom: 0;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        padding: 2rem;

        .footer-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include media-breakpoint-down(md) {
                margin-bottom: 1rem;
            }

            img.footer-logo {
            }

            .blurb {
                text-align: center;
                margin-bottom: 0;
                max-width: 600px;
            }

            .btn {
                align-self: center;
                margin-bottom: 3rem;
            }
        }

        .footer-links {
            width: 100%;
            margin: 2rem 0;
            flex-direction: row !important;
            flex-wrap: wrap;
            .footer-item {
                flex: 0 0 50%;
                display: flex;
                justify-content: flex-start;
                @include media-breakpoint-down(sm) {
                    flex: 0 0 100%;
                }
            }
        }

        .footer-communities {
            .footer-item {
                flex: 0 0 50%;
                display: flex;
                justify-content: flex-start;
                @include media-breakpoint-down(sm) {
                    flex: 0 0 100%;
                }
                .community {
                    width: max-content;
                }
            }
        }
        .social-links {
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;
        }
        .policies {
            flex-direction: column;
            .footer-item {
                margin: 1rem 0;
                a {
                    text-align: center;
                }
            }
        }
    }
}
