nav.navbar {
	padding: rem-val(10) rem-val(110);
	background: #ffffff;
	box-shadow: 2px 2px 15px 10px rgba(0, 0, 0, 0.05);
	position: sticky;
	top: 0;
	z-index: 100000;
	@media (max-width: 1600px) {
		padding: rem-val(10) rem-val(30);
		.navbar-collapse {
			.navbar-nav {
				flex-wrap: wrap;
			}
		}
	}
	@media (max-width: 1440.98px) {
		padding: rem-val(10);
	}

	// @Style

	.navbar-brand {
		img {
			@media (max-width: 1450px) {
				width: 200px;
				height: auto;
			}
		}
	}
	@include media-breakpoint-down(xxl) {
		.navbar-brand {
			align-self: flex-start;
		}
	}
	@include media-breakpoint-down(xl) {
		padding: 1rem;
		.navbar-brand {
			order: 1;
		}
		.navbar-toggler {
			order: 2;
		}
		.navbar-collapse {
			order: 3;
		}
	}
	.navbar-collapse {
		.navbar-nav {
			align-items: center;
			&.top-nav {
				margin-bottom: 1.5rem;
				.nav-item {
					padding: 0.5rem 1.5rem;
					@include media-breakpoint-down(xxl) {
						padding: 0.5rem 0.5rem;
					}
					.nav-link {
						padding: 0;
						text-transform: uppercase;
						font-size: rem-val(18);
						letter-spacing: 2px;
						white-space: nowrap;
						@include media-breakpoint-down(xxl) {
							font-size: 1rem;
						}
					}

					&.active {
						.nav-link {
						}
					}
				}
			}

			&.bottom-nav {
				margin-bottom: 0rem;
				.nav-item {
					padding: 0.5rem 2rem;
					.nav-link {
						padding: 0;
						color: $black;
						font-size: rem-val(16);
						letter-spacing: 2.25px;
						@include media-breakpoint-down(xxl) {
							font-size: rem-val(14);
						}
					}

					&.active {
						.nav-link {
						}
					}
					&.btn {
						margin-left: rem-val(60);
						a.nav-link {
							color: $white;
						}
					}
				}
			}
		}
		&.collapse {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			@include media-breakpoint-down(xl) {
				display: none;
			}
			.support-section {
				&.mobile {
					display: none;
				}
			}
		}
		&.collapsing,
		&.show {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			height: 90vh;
			overflow-y: scroll;
			@include media-breakpoint-down(xl) {
				align-items: flex-start;
				padding-top: rem-val(25);
				.navbar-nav {
					align-items: flex-start;
					margin-bottom: 0;
					width: 100%;
					&.top-nav {
						order: 2;
						padding: rem-val(25) 0;
						border-bottom: 1px solid #e5e5e5;
					}
					&.bottom-nav {
						order: 1;
						.btn {
							display: none;
						}
					}
					.nav-item:not(.btn) {
						padding: 0.5rem;
					}
					.nav-item {
						margin-bottom: 0.5rem;
						width: 100%;
						padding: 0.5rem 0;
						a {
							font-family: "Poppins";
							font-size: rem-val(14);
							font-weight: 100;
							color: #565656;
							letter-spacing: 1.75px;
							line-height: 45px;
						}
						&.dropdown {
							padding: rem-val(20) 0;
							&:first-of-type {
								border-top: 1px solid #e5e5e5;
							}
							border-bottom: 1px solid #e5e5e5;
							a {
								font-family: "Poppins";
								font-weight: 300;
								font-size: 1rem;
								color: $primary;
								letter-spacing: 2px;
								line-height: 45px;
							}
							.dropdown-menu {
								border: none;
								height: 100%;
								max-height: 0px;
								overflow: hidden;
								transition: max-height 0.3s ease-out;
								display: block;
								padding: 0;
								margin: 0;

								&.show {
									max-height: 1000px;
								}
								.dropdown-item {
									font-family: "Poppins";
									font-weight: 100;
									font-size: rem-val(14);
									color: $black;
									letter-spacing: 1.75px;
									line-height: 45px;
								}
							}
						}
					}
					.search {
						margin-left: 0rem;
						padding: 0.5rem;
						min-width: 275px;
						margin-bottom: 0.5rem;
					}
					.nav-item.btn {
						margin-left: 0;
					}
				}
				.support-section {
					display: none;
					&.mobile {
						padding: rem-val(50) 0;
						display: block;
						order: 3;
						a.btn {
							margin-bottom: 2rem;
						}
						form {
							margin-left:0!important;
						}
					}
				}
			}
		}
	}
}

#search-form {
	display: flex;
	margin-left: rem-val(45);
	min-width: 250px;
	@include media-breakpoint-down(xxl) {
		margin-left: rem-val(20);
	}
	#search {
		background: transparent;
		border: none;
		border-bottom: 1px solid #979797;
		width: 100%;
		outline: none;
		&::placeholder,
		&:placeholder-shown {
			font-size: 1rem;
			text-align: left;
			text-transform: uppercase;
			font-family: "Poppins";
			font-weight: 100;
			letter-spacing: 1.33px;
		}
	}
	#searchSubmit {
		background: none;
		color: #979797;
		border: none;
		padding: 0 0.5rem 0 0;
		font-size: 1.25rem;
		cursor: pointer;
		margin-left: -1.5rem;
		margin-bottom: rem-val(5);
	}
}
