body.freeze {
    overflow: hidden;
}
body {
    overflow-anchor: none;
}

html {
    scroll-behavior: smooth;

}

.btn {
    padding: 0.25rem 4rem;
    line-height: 1.5;
    font-size: rem-val(18);
    @include media-breakpoint-down(xxl) {
        font-size: 1rem;
    }
    @include media-breakpoint-down(md) {
        padding: 0.25rem 1rem;
    }
}

.btn-primary {
    font-family: "Poppins";
    font-size: rem-val(18);
    color: #ffffff;
    letter-spacing: 2.25px;
    text-align: center;
    line-height: 45px;
}

.btn-white {
    font-family: "Poppins";
    font-size: rem-val(18);
    color: $primary;
    letter-spacing: 2.25px;
    text-align: center;
    line-height: 45px;
}

.modal {
    &.show {
        background-color: rgba($secondary, 0.5);
    }
    .modal-dialog {
        .modal-content {
            position: relative;
            [class*="modal"] {
            }
            .modal-body {
                // padding: 0;
            }
            .btn-close {
                position: absolute;
                right: -70px;
                top: -25px;
                color: $white;
                background-color: $white;
                @include media-breakpoint-down(lg) {
                    top: -50px;
                    right: 0px;
                }
            }
        }
    }
}

@mixin cross($size: 215px, $color: currentColor, $thickness: 1px) {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    position: relative;
    width: $size;
    height: $size;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: calc(($size - $thickness) / 2);
        left: 0;
        right: 0;
        height: $thickness;
        background: $color;
        border-radius: $thickness;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    span {
        display: block;
    }
}

.modal {
    background-color: rgba($secondary, 0.5);
    z-index: 100000;
    .modal-dialog {
        max-width: 50vw;
        height: 100vh;
        margin: 0 auto;
        @include media-breakpoint-down(lg) {
            max-width: 90vw;
        }
        .modal-content {
            position: relative;
            .btn-close {
                margin: 0;
                border: 0;
                padding: 0;
                background: $primary;
                border-radius: 50%;
                width: 60px;
                height: 60px;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 150ms;

                .icon-cross {
                    @include cross(30px, #fff, 4px);
                }

                @include media-breakpoint-down(lg) {
                    width: 45px;
                    height: 45px;
                    .icon-cross {
                        @include cross(30px, #fff, 4px);
                    }
                }

                &:hover,
                &:focus {
                    transform: rotateZ(90deg);
                    background: $secondary;
                }
            }

            .modal-body {
                padding: 0;
                height: 70vh;
                overflow-y: scroll;

                .video-wrapper {
                    position: relative;
                    padding-bottom: 56.25%; /* 16:9 */
                    padding-top: 25px;
                    height: 0;
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
                .content-wrapper {
                    padding: 2rem 1rem;
                }
            }
        }
        &.video-only {
            .modal-content {
                .modal-body {
                    height: unset;
                    overflow-y: unset;
                }
            }
        }
    }
}
