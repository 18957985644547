.backgrounded-item {
    // display: none;
    // overflow: hidden;
    .bottom-border {
        border-bottom: 1px solid $primary;
    }
    .scroll-div {
        display: block;
        position: relative;
        top: -400px;
        visibility: hidden;
    }
    @import "./side_by_side";
}
