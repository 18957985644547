&.list_items_content {
    .list-items {
        display: grid;
        margin-bottom: 0;
        &.one-column {
            grid-template-columns: 1fr;
            &.bullets {
                padding-left: 2rem;
            }
        }
        &.two-column {
            grid-template-columns: 1fr 1fr;
            row-gap: 25px;
            column-gap: 20px;
            padding-left: 0;
            @include media-breakpoint-down(sm) {
                grid-template-columns: 1fr;
            }
        }
        &.full-width {
            .item {
                justify-content: space-between;
            }
        }
        &.icons {
            .item {
                align-items: flex-start;
                padding-bottom: 0;
                img {
                    margin-top: 8px;
                }
                .icons {
                    p {
                        font-size: rem-val(21);
                        margin: 0;
                    }
                }
            }
        }
        &.none,
        &.icons-right-side {
            padding-left: 0;
        }
        li.item-with-link {
            list-style-type: none;
            &.seperator {
                padding: rem-val(22.5) 0;
                border-bottom: 1px solid #979797;
                &:first-of-type {
                    border-top: 1px solid #979797;
                }
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                margin: 0;
            }
            a {
                text-decoration: none;
            }
            p {
                margin-bottom: 0px;
                font-weight: 100;
            }
        }
        li.item,
        a.item {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            // padding-bottom: rem-val(10);
            &.seperator {
                padding: rem-val(22.5) 0;
                border-bottom: 1px solid #979797;
                &:first-of-type {
                    border-top: 1px solid #979797;
                }
            }
            &.display-icon {
                &--right {
                    img {
                        order: 2;
                        margin-left: 0.5rem;
                    }
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    p {
                        padding-right: 0.5rem;
                    }
                }
                &--left {
                    img {
                        // order: 1;
                        margin-right: 0.5rem;
                    }
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    p {
                        padding-left: 0.5rem;
                    }
                }
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                margin: 0;
            }
            a {
                text-decoration: none;
            }
            p {
                margin-bottom: 0px;
                font-weight: 100;
            }
        }
        @include media-breakpoint-down(md) {
            padding-left: 0;
        }
    }
}
