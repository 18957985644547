h1,
.h1 {
    font-size: rem-val(77);
    line-height: 1.03896103896;
    @include media-breakpoint-down(xxl) {
        font-size: rem-val(60);
        line-height: 1.06666666667;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: rem-val(57.75);
    }
}
h2,
.h2 {
    font-size: rem-val(52);
    line-height: 1.15384615385;
    @include media-breakpoint-down(xxl) {
        font-size: rem-val(40);
        line-height: 1.2;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: rem-val(39);
    }
}
h3,
.h3 {
    font-size: rem-val(42);
    line-height: 1.38095238095;
    @include media-breakpoint-down(xxl) {
        font-size: rem-val(32);
        line-height: 1.3125;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: rem-val(31.5);
    }
}
h4,
.h4 {
    font-size: rem-val(32);
    line-height: 1.3125;
    @include media-breakpoint-down(xxl) {
        font-size: rem-val(28);
        line-height: 1.71428571429;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: rem-val(24);
    }
}
h5,
.h5 {
    font-size: rem-val(28);
    line-height: 1.71428571429;
    @include media-breakpoint-down(xxl) {
        font-size: rem-val(24);
        line-height: 1.75;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: rem-val(21);
    }
}
h6,
.h6 {
    font-size: rem-val(24);
    line-height: 1.75;
    @include media-breakpoint-down(xxl) {
        font-size: rem-val(18);
        line-height: 1.77777777778;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: rem-val(16);
    }
}

.paragraph,
p {
    font-size: rem-val(24);
    font-family: "Poppins";
    line-height: 1.75;
    font-weight: 100;
    @include media-breakpoint-down(xxl) {
        font-size: rem-val(20);
        line-height: 1.75;
    }
    @include media-breakpoint-down(sm) {
        font-size: rem-val(20);
    }
}

// p + ul,ol {
//     margin-bottom: 0;
// }

ul:not(.acf-radio-list, .navbar-nav),
ol:not(.acf-radio-list, .navbar-nav) {
    margin-bottom: 2rem;
    li {
        font-size: rem-val(24);
        font-family: "Poppins";
        line-height: 1.75;
        font-weight: 100;
        margin-bottom: rem-val(5);
        @include media-breakpoint-down(xxl) {
            font-size: rem-val(18);
            line-height: 1.77777777778;
        }
        // @include media-breakpoint-down(sm) {
        //     font-size: rem-val(20);
        // }
    }
}