&.image_content {
    .image-wrapper {
        width: 100%;
        position: relative;
        &.offcentered-bg {
            @each $key, $color in $theme-colors {
                &--#{$key}--top-right-width {
                    &:before {
                        width: calc(75% + 55px);
                        height: calc(60% + 47.5px);
                        top: 0;
                        right: 0;
                        content: "";
                        position: absolute;
                        background-color: #{$color};
                    }
                    img {
                        padding: 55px 47.5px 0px 0px;
                        position: relative;
                    }
                }
                &--#{$key}--top-right-height {
                    &:before {
                        width: calc(46% + 180px);
                        height: calc(74% + 64px);
                        top: 0;
                        right: 0;
                        content: "";
                        position: absolute;
                        background-color: #{$color};
                    }
                    img {
                        padding: 64px 180px 0px 0px;
                        position: relative;
                    }
                    @include media-breakpoint-down(sm) {
                        img {
                            padding: 34px 80px 0 0;
                        }
                        &:before {
                            width: calc(46% + 80px);
                            height: calc(74% + 34px);
                        }
                    }
                }
                &--#{$key}--bottom-right {
                    &:before {
                        width: calc(40% + 50px);
                        height: calc(36% + 50px);
                        bottom: 0;
                        right: 0;
                        content: "";
                        position: absolute;
                        background-color: #{$color};
                    }
                    img {
                        padding: 0px 50px 50px 0px;
                        position: relative;
                    }
                }
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .frozen-flip-box-image {
            background-position: center;
            background-size: cover;
            padding: 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: var(--flip-box-height);
            @include media-breakpoint-down(sm) {
                height: calc(var(--flip-box-height) / 1.25);
            }
            &:after {
                content: "";
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
                width: 100%;
                height: 100%;
            }
            .content {
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 5;
                @include media-breakpoint-down(sm) {
                    p {
                        display: none;
                    }
                }
            }
        }

        .flip-box {
            .flip-box-inner {
                position: relative;
                .flip-box-back {
                    position: absolute;
                    background: rgba(0, 0, 0, 0.6);
                    width: 100%;
                    height: 100%;
                    top: 0;                    
                    z-index: -1;
                    opacity: 0;
                    transition: opacity 0.5s ease-out;
                    .content {
                        height: 100%;
                        padding: 3rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-size: cover;
                        z-index: 5;
                        transition: filter 0.5s ease-out;
                        filter: brightness(0);
                        @include media-breakpoint-down(md) {
                            filter: brightness(100%);
                        }
                        @include media-breakpoint-down(sm) {
                            padding: 1rem;
                            p {
                                display: none;
                            }
                        }
                    }
                    @include media-breakpoint-down(lg) {
                        z-index: 1;
                        clip-path: unset;
                    }
                    @include media-breakpoint-down(md) {
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
            &:hover, &:focus-within, &:focus {
                .flip-box-inner {
                    .flip-box-back {
                        opacity: 1;
                        z-index: 1;
                        .content {
                            filter: brightness(100%);
                        }
                    }
                }
            }
            // background-color: transparent;
            // width: 100%;
            // perspective: 1000px;
            // height: var(--flip-box-height);
            // @include media-breakpoint-down(sm) {
            //     height: calc(var(--flip-box-height) / 1.25);
            // }
            // &:hover {
            //     .flip-box-inner {
            //         transform: rotateY(180deg);
            //     }
            // }
            // .flip-box-inner {
            //     position: relative;
            //     width: 100%;
            //     height: 100%;
            //     text-align: center;
            //     transition: transform 1s;
            //     transform-style: preserve-3d;
            //     .flip-box-front,

            //     .flip-box-back {
            //         position: absolute;
            //         width: 100%;
            //         height: 100%;
            //         -webkit-backface-visibility: hidden; /* Safari */
            //         backface-visibility: hidden;
            //     }
            //     .flip-box-front {
            //         background-color: #bbb;
            //         color: black;
            //     }
            //     .flip-box-back {
            //         padding: 3rem;
            //         transform: rotateY(180deg);
            //         display: flex;
            //         flex-direction: column;
            //         align-items: center;
            //         justify-content: center;
            //         background-size: cover;
            //         @include media-breakpoint-down(sm) {
            //             padding: 1rem;
            //         }
            //         &:after {
            //             content: "";
            //             background: rgba(0, 0, 0, 0.6);
            //             position: absolute;
            //             width: 100%;
            //             height: 100%;
            //         }
            //         .content {
            //             max-width: 500px;
            //             color: white;
            //             display: flex;
            //             flex-direction: column;
            //             align-items: center;
            //             justify-content: center;
            //             position: relative;
            //             z-index: 5;
            //         }
            //     }
            // }
        }
    }
}
