&.toggle_content {
    .card {
        margin-bottom: 1.2rem;
        box-shadow: none;
        .card-header {
            background: #f6f6f6;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            letter-spacing: 0;
            height: 50px;
            display: flex;
            align-items: center;
            button {
                width: 100%;
                padding: 0;
                display: flex;
                justify-content: space-between;
                color: #000000;
                text-decoration: none;
                svg {
                    path {
                        fill: $black;
                    }
                }
            }
        }
        .card-body {
            display: block;
            align-items: start;
        }
    }
}