&.text_content {
    @media screen and (max-width: 325px) {
        // word-break: break-all;
    }
    .accent-line-wrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        span {
            width: 40%;
            height: 1px;
            background-color: $dark;
            margin-left: 1rem;
            margin-top: 1.25rem;
            &.h2 {
                margin-top: 1.75rem;
            }
        }
    }
    &.Baskerville {
        letter-spacing: 3px;
        .has-accent-line {
            font-size: rem-val(18);
        }
    }
}