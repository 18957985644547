$asset-dir: '/wp-content/themes/usdigital-child/assets/dist/';

/// @group Fonts
// import your fonts here

/// @group Bootstrap
// Bootstrap Source
// We'll pick choose the BS modules we want 
// to use for this project.
// scss-docs-start import-stack
@import 
    '~bootstrap/scss/functions', 
    '~bootstrap/scss/variables',
    'bootstrap.scss',
    "~bootstrap/scss/maps",
    '~bootstrap/scss/mixins', 
    '~bootstrap/scss/utilities', 
    '~bootstrap/scss/root', 
    '~bootstrap/scss/reboot', 
    '~bootstrap/scss/type', 
    '~bootstrap/scss/images', 
    '~bootstrap/scss/containers', 
    '~bootstrap/scss/grid', 
    // '~bootstrap/scss/tables', 
    '~bootstrap/scss/forms', 
    '~bootstrap/scss/buttons', 
    '~bootstrap/scss/transitions', 
    '~bootstrap/scss/dropdown', 
    '~bootstrap/scss/button-group', 
    '~bootstrap/scss/nav', 
    '~bootstrap/scss/navbar', 
    '~bootstrap/scss/card', 
    // '~bootstrap/scss/accordion', 
    // '~bootstrap/scss/breadcrumb', 
    // '~bootstrap/scss/pagination', 
    // '~bootstrap/scss/badge', 
    // '~bootstrap/scss/alert',
    // '~bootstrap/scss/progress', 
    // '~bootstrap/scss/list-group', 
    // '~bootstrap/scss/close', 
    // '~bootstrap/scss/toasts', 
    '~bootstrap/scss/modal', 
    // '~bootstrap/scss/tooltip', 
    // '~bootstrap/scss/popover', 
    // '~bootstrap/scss/carousel', 
    // '~bootstrap/scss/spinners', 
    // '~bootstrap/scss/offcanvas', 
    '~bootstrap/scss/helpers', 
    '~bootstrap/scss/utilities/api';
// scss-docs-end import-stack


/// @group Config
@import 
    'functions',
    'accessibility';

/// @group Mixins 
// Support Functionality (e.g. media queries, button variants, etc.)
@import
  'mixins/actions',
  'mixins/common',
  'mixins/container',
  'mixins/font',
  'mixins/form',
  'mixins/gradient',
  'mixins/media-queries',
  'mixins/position',
  'mixins/scrollbar';

/// @group Blocks
// @import 
//     'blocks/gutenberg';

/// @group WooCommerce
// @devnote move to own entry
// @import
//     'woo/woocommerce.scss';


/// @group Vendor - 3rd Party Customizations 
// Framework / Plugin Specific styling (e.g. Wordpress, Gravity Forms, WooCommerce, etc.)
@import 
        'vendor/splide.scss';
//     'vendor/wp',
//     'vendor/gf';

/// @group Glide
// @import 
//     '~@glidejs/glide/src/assets/sass/glide.core.scss';

/// @group Global
// Group of global stlying
@import
    "global/fonts.scss",
    'global/typography',
    'global/globals.scss',
    'global/forms.scss',
    'global/utilities.scss',
    'global/animations.scss';

/// @group Components 
// Grouped components (e.g. .carousel, .navbar, .modal)
@import
    'components/footer',
    'components/header',
    'components/gdpr';

/// @group Singles
@import 'singles/blog';
@import 'singles/communities';
@import 'singles/search';

/// @group Archive
@import 'archives/blog';

/// @group FleixbleContent
@import 
'flexible_containers/backgrounded',
'flexible_content/content-wrapper';
    

// Browser overrides
:focus-visible {
    outline-color: $primary;
}

.page-title {
    margin-left: 10px;
}