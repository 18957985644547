// @media screen and (min-width: 576px) {
//     .hide-xs {
//         display: none;
//     }
// }
@media screen and (min-width: 0px) and (max-width: 576px) {
    .hide-sm {
        display: none;
    }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
    .hide-md {
        display: none;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .hide-lg {
        display: none;
    }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .hide-xl {
        display: none;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1440px) {
    .hide-xxl {
        display: none;
    }
}

section[name="communities-single"] {
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        .hide-xl {
            display: none;
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .order-first-xs {
        order: 1;
    }
    .order-last-xs {
        order: 2;
    }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
    .order-first-sm {
        order: 1;
    }
    .order-last-sm {
        order: 2;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .order-first-md {
        order: 1;
    }
    .order-last-md {
        order: 2;
    }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .order-first-lg {
        order: 1;
    }
    .order-last-lg {
        order: 2;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1440px) {
    .order-first-xl {
        order: 1;
    }
    .order-last-xl {
        order: 2;
    }
}
