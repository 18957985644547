#blog-archive {
    section.hero {
        min-height: 700px;
        padding: rem-val(150) rem-val(100);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(lg) {
            padding: rem-val(75) rem-val(50);
            display: flex;
            align-items: center;
            min-height: unset;
        }
        @include media-breakpoint-down(md) {
            padding: rem-val(25);

        }
        .card {
            max-width: 1100px;
            min-height: 400px;
            padding: rem-val(60) rem-val(80);
            justify-content: center;
            @include media-breakpoint-down(md) {
                padding: rem-val(40);
                min-height: unset;
            }
            h1 {
                margin-bottom: rem-val(40);
                font-size: rem-val(100);
                letter-spacing: -1.5px;
                line-height: 1;
                @include media-breakpoint-down(md) {
                    font-size: rem-val(57.75);
                    margin-bottom: rem-val(20);
                }
            }
            h2 {
                font-family: "Poppins";
                font-weight: 100;
                font-size: rem-val(32);
                color: #262c5d;
                letter-spacing: 0;
                line-height: 48px;
                margin-bottom: 0;
                @include media-breakpoint-down(md){
                    font-size: rem-val(24);
                }
            }
            .post-details {
                display: flex;
            }
        }
    }
    #blog-archive-content {
        padding: rem-val(100);
        @include media-breakpoint-down(lg) {
            padding: rem-val(50);
        }
        @include media-breakpoint-down(sm) {
            padding: 2rem 1rem;
        }
        #sticky-post {
            background: #FFFFFF;
            box-shadow: 2px 2px 15px 10px rgba(0,0,0,0.05);
            border: none;
            .sticky-post-image {
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                @include media-breakpoint-down(xl) {
                    height: 350px;
                }
            }
            .sticky-post-content {
                padding: rem-val(80) rem-val(135) rem-val(80) rem-val(100);
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include media-breakpoint-down(lg) {
                    padding: rem-val(40) rem-val(50);
                }
                @include media-breakpoint-down(md) {
                    padding: rem-val(40) 1.5rem;
                }
                .sticky-subtitle {
                    font-size: rem-val(20);
                    font-weight: 100;
                    font-family: "Poppins";
                    text-transform: uppercase;
                    margin-bottom: 1rem;
                }
                .sticky-post-excerpt {
                    font-size: rem-val(20);
                    margin-bottom: rem-val(60);
                }
                .sticky-post-link {
                    width: fit-content;
                }
            }
        }
    }
    #blog-content {
        // padding-top: rem-val(110);
    }
}
