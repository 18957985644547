&.video_modal_content {
    width: auto;
    height: 500px;
    @include media-breakpoint-down(md) {
        height: 400px;
    }
    @include media-breakpoint-down(sm) {
        height: 300px;
    }
    .video-bg {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-size: cover;
        &.right {
            flex-direction: row;
            p {
                margin: 0 0 0 0.5rem;
            }
        }
        &.with-text {
            img {
                width: 50px;
                height: 50px;
            }
        }

        img {
            width: 84px;
            height: 84px;
        }
        p {
            color: $white;
            margin: 0;
        }
    }
    &.form {
        .modal-dialog {
            max-width: 700px;
            margin: 125px auto 0;
            @include media-breakpoint-down(lg) {
                max-width: 90vw;
            }
            @include media-breakpoint-down(md) {
                max-width: 95vw;
                margin: 175px auto 0;
            }
            .modal-content {
                @include media-breakpoint-down(md) {
                    .btn-close {
                        right: 0;
                        top: -30px;
                    }
                }
                .modal-body {
                    padding: rem-val(60) rem-val(90);
                    border-radius: 25px;
                    @include media-breakpoint-down(md) {
                        padding: rem-val(30);
                    }
                    .form-wrapper {
                        .gform_wrapper {
                            padding: 0;
                            .gform_heading {
                                text-align: center;
                                h2 {
                                    color: $secondary;
                                }
                                .gform_required_legend {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}