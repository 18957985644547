&.side_by_side_container {
    .container-fluid,
    .container {
        .row {
            height: 100%;
            @include media-breakpoint-down(xl) {
                height: auto;
            }
        }
    }
}
