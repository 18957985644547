&.card_content {
    width: auto;
    @media screen and (max-width: 450px) {
        width: 100%;
    }
    .card {
        height: 100%;
        background: $white;
        box-shadow: 2px 2px 15px 10px rgba(0, 0, 0, 0.05);
        border: none;
        padding: 0;
        .card-header {
            padding: 0;
            img {
                width: 100%;
            }
        }
        .card-body {
            .card-content {
                height: 100%;
                p {
                    line-height: 1.6;
                    &:only-child {
                        margin-bottom: 0;
                    }
                }
                h3:first-of-type {
                    margin-bottom: 1rem;
                }

                a {
                    margin-top: auto;
                }
            }
        }
        &.small {
            max-width: 430px;
            .card-body {
                width: 100%;
                padding: rem-val(26) rem-val(36) rem-val(32);
                .card-content {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    a.btn {
                        padding: .25rem 2rem;
                    }
                }
            }
        }

        &.large {
            max-width: 622px;
            .card-body {
                padding: rem-val(50) rem-val(60);
                @include media-breakpoint-down(md) {
                    padding: rem-val(32) rem-val(16);
                }
                .card-content {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        &.full-width {
            max-width: 100%;
            .card-body {
                padding: rem-val(50) rem-val(100);
                @include media-breakpoint-down(xxl) {
                    padding: 3rem 2rem 2rem;
                }
                @include media-breakpoint-down(md) {
                    padding: 2rem 1rem;
                }
                .list-items {
                    // display: grid;
                    // margin-bottom: 2rem;
                    columns: 2;
                    @include media-breakpoint-down(md) {
                        columns: 1;
                    }
                    .item {
                        padding: 0 1rem 1rem;
                        margin-bottom: 1rem;
                        break-inside: avoid;
                        @include media-breakpoint-down(sm) {
                            margin-bottom: .5rem;
                        }
                    }
                }
            }
        }
        &.horizontal {
            max-width: 100%;
            display: flex;
            flex-direction: row;
            // flex-wrap: nowrap;
            .card-header {
                flex: 0 0 25%;
                img {
                    height: 100%;
                }
            }
            .card-body {
                flex: 1;
                padding: rem-val(85) rem-val(120);
                display: flex;
                flex-direction: row;
                .card-content {
                    flex: 1 1 62%;
                    padding-right: rem-val(20);
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    .card-title {
                        font-family: "Poppins";
                        font-weight: 100;
                    }
                    h3 {
                        margin-bottom: rem-val(20);
                    }
                    p {
                        margin-bottom: 0;
                        font-size: rem-val(20);
                        &:last-of-type {
                            margin-bottom: 2rem;
                        }
                    }
                }
                .card-divider {
                    margin: 0 rem-val(50);
                    background-color: $primary;
                    width: 1px;
                    height: 100%;
                }
                .card-footer {
                    flex: 1 1 38%;
                    height: 100%;
                    background-color: $white;
                    border: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    p {
                        font-size: rem-val(20);
                    }
                    .card-location {
                        p {
                            font-family: "Poppins";
                            font-weight: 100;
                        }
                    }
                    .card-support-copy {
                        h4 {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            @media (max-width: 1439.98px) {
                .card-header {
                    display: none;
                }
            }
            @include media-breakpoint-down(xl) {
                flex-direction: column;
                .card-body {
                    .card-divider {
                        display: none;
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                .card-body {
                    padding: 2rem 1rem;
                }
            }

            @include media-breakpoint-down(md) {
                .card-body {
                    flex-direction: column;
                    .card-content {
                        flex-direction: column;
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }
}
