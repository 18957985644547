&.testimonial_content {
    .splide {
        margin: 0 auto;
        .splide__track {
            .splide__list {
                .splide__slide {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    h3 {
                        font-weight: 300;
                        letter-spacing: 0.44px;
                        line-height: 60px;
                    }
                    h4 {
                        font-family: Vollkorn;
                        font-size: 21px;
                        line-height: 34px;
                    }
                }
            }
        }

        .splide__pagination {
            bottom: unset;
        }
    }
}