.hero-search {
    min-height: 300px;
    background-color: $primary;
    padding: 2rem rem-val(70);
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
        padding: 2rem rem-val(40);
    }
    h1 {
        color: $white;
        margin-bottom: 0;
        span {
            text-decoration: underline;
        }
    }
}

.search-results {
    padding-top: 3rem;
    padding-bottom: 3rem;
    .search-result {
        padding: 2rem 1rem;
    }
}

.blog-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem .5rem 2rem;    
    .btn {
        &:first-of-type {
            align-self: flex-start;
        }
        &:last-of-type {
            align-self: flex-end;
        }
    }
    .pages {
        padding: 0 1rem;
        a {
            margin: 0 0.25rem;
            padding: 0 0.25rem;
            &.current {
                text-decoration: none;
            }
        }
        &.no-prev {
            margin-left: 240px;
        }
        &.no-next {
            margin-right: 240px;
        }
    }
    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        .pages {
            order: 3;
            flex: 1 0 100%;
        }
        .btn {
            margin: 1rem 0;
            &.prev {
                order: 1;
            }
            &.next {
                order: 2;
            }
        }
    }
}
