section[name="communities-single"] {
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-auto-rows: auto;
    gap: 0px 0px;
    padding-bottom: 125px;

    @include media-breakpoint-down(xl) {
        display: block;
        position: relative;
        overflow: hidden;
    }

    #sidebar-menu-toggle {
        position: fixed;
        bottom: 45px;
        right: 15px;
        transition: all cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s;
        z-index: 1;
        z-index: 1002;
        background-color: transparent;
        border: none;
        padding: 0;
        .wrapper {
            width: 50px;
            height: 50px;
            background-color: $white;
            box-shadow: 2px 2px 15px 10px rgba(0, 0, 0, 0.05);
            border-radius: 25px;
            .icon-1,
            .icon-2,
            .icon-3 {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 32px;
                height: 3px;
                background-color: black;
                transition: all 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
            }

            .icon-1 {
                transform: translate(-50%, -8px);
                animation-delay: 100ms;
            }

            .icon-2 {
                transform: translate(-50%, 0px);
            }

            .icon-3 {
                transform: translate(-50%, 8px);
                animation-delay: 250ms;
            }

            .clear {
                clear: both;
            }
        }
        &.sidebar-is-open {
            .wrapper {
                .icon-1 {
                    transform: translateX(-50%) rotate(40deg);
                }
                .icon-3 {
                    transform: translateX(-50%) rotate(-40deg);
                }
                .icon-2 {
                    opacity: 0;
                }
            }
        }
    }
    @include media-breakpoint-up(xl) {
        #sidebar-menu-toggle {
            display: none;
        }
    }

    section[name="communities-sidebar"] {
        grid-area: 1 / 1 / 3 / 2;
        background: #ffffff;
        box-shadow: 2px 2px 15px 10px rgba(0, 0, 0, 0.05);
        z-index: 1;
        .sticky {
            padding: rem-val(40);
            position: sticky;
            top: 0;
            height: 100vh;
            overflow-y: scroll;
            @include media-breakpoint-down(xl) {
                padding: 0;
            }
            .logo {
                padding-bottom: 1.5rem;
            }
            .inner-links {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid $dark;
                a {
                    margin: rem-val(15) 0;
                    padding: 0.25rem 0 0.25rem;
                    font-family: "Poppins";
                    font-size: rem-val(20);
                    color: $primary;
                    letter-spacing: 0.5px;
                    line-height: 45px;
                    text-decoration: none;
                    position: relative;
                    &.active {
                        color: $primary;
                        font-weight: 700;
                    }
                }
            }
            .location-details {
                padding: 1.25rem 0;
                margin-bottom: 2rem;
                border-bottom: 1px solid $dark;

                p,
                a {
                    font-family: Poppins;
                    font-weight: 100;
                    font-size: 1rem;
                    color: $black;
                    letter-spacing: 1px;
                    line-height: 35px;
                    text-decoration: none;
                    margin-bottom: 1.25rem;
                }
            }
        }
        @include media-breakpoint-down(xl) {
            position: fixed;
            right: calc(-100% + -2.5rem);
            z-index: 1001;
            height: 100%;
            // top: 40px;
            overflow-y: scroll;
            transition: all cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s;
            max-width: 400px;
            width: 85%;
            padding: rem-val(40) 1rem;
            &.sidebar-is-open {
                right: 0;
            }
            .sticky {
                .logo {
                    img {
                        max-width: 308px;
                        width: 100%;
                        height: auto;
                    }
                }
                .inner-links {
                    a {
                        padding: 0.25rem 1rem 0.25rem 0;
                        margin: 0.5rem 0;
                        line-height: 1.5;
                    }
                }
            }
        }
    }
    section[name="communities-hero"] {
        grid-area: 1 / 2;
        section[name="hero"] {
            .hero-content {
                margin-top: 75px;
                min-height: 200px;
                height: auto;
                background-color: $primary;
                padding: 1rem rem-val(70);
                display: flex;
                align-items: center;
                h1 {
                    color: $white;
                    margin-bottom: 0;
                    line-height: 1.2;
                }
                @include media-breakpoint-down(sm) {
                    padding: 1rem;
                    height: auto;
                }
            }
        }
        section[name="breadcrumbs"] {
            padding: 0.5rem rem-val(70);
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @include media-breakpoint-down(lg) {
                line-height: 1;
            }
            @include media-breakpoint-down(md) {
                padding: 0.5rem 1rem;
            }

            a {
                margin: rem-val(5) 0.25rem;
                font-size: rem-val(18);
                color: $black;
                display: flex;
                align-items: center;
                text-decoration: none;
                &.underline {
                    text-decoration: underline;
                }
                img {
                    margin-right: 6px;
                }
            }
            span {
                margin: 0 6px;
            }
        }
    }
    section[name="communities-content"] {
        grid-area: 2 / 2 / 3 / 3;
        &.parent {
            margin-top: 76px;
        }
    }
    section[name="homepage-return"] {
        grid-area: 1 / 2;
        background-color: $tertiary;
        height: 76px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 1000;
        a {
            color: $black;
            font-size: rem-val(18);
            font-weight: 300;
            text-decoration: none;
            letter-spacing: 2.25px;
            line-height: 45px;
        }
        @include media-breakpoint-down(xl) {
            padding: 1rem;
            text-align: center;
            position: fixed;
            a {
                font-size: rem-val(16);
                letter-spacing: 1;
                line-height: 1.2;
            }
        }
    }
}

body {
    &.sidebar-is-open {
        overflow: hidden;
    }
}
