#blog-post {
    section.hero {
        min-height: 700px;
        padding: rem-val(150) rem-val(100);
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 1439.98px) {
            padding: rem-val(75) rem-val(50);
        }
        @include media-breakpoint-down(lg) {
            min-height: unset;
        }
        @include media-breakpoint-down(md) {
            padding: rem-val(25);
        }
        .card {
            max-width: 1100px;
            min-height: 400px;
            padding: rem-val(60) rem-val(80);
            justify-content: center;
            @include media-breakpoint-down(md) {
                padding: rem-val(30) rem-val(40);
            }
            h1 {
                margin-bottom: rem-val(60);
                font-size: rem-val(100);
                letter-spacing: -1.5px;
                line-height: 1;
                @include media-breakpoint-down(lg) {
                    font-size: rem-val(77);
                }
                @include media-breakpoint-down(md) {
                    font-size: rem-val(57);
                    margin-bottom: rem-val(30);
                }
                @include media-breakpoint-down(sm) {
                    letter-spacing: 1;
                    font-size: rem-val(45);
                    margin-bottom: rem-val(20);
                }
            }
            .post-details {
                display: flex;
                .categories {
                    a {
                        margin: 0 rem-val(5);
                    }
                }
                span {
                    margin: 0 rem-val(20);
                }
                .author {
                    margin-right: rem-val(20);
                }
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    #blog-content {
        padding-top: rem-val(100);
        .wp-block-group.blog-post-container {
            .wp-block-group__inner-container {
                padding: 1rem 1rem rem-val(100);
                max-width: calc(900px + 2rem);
                margin: 0 auto;
            }
        }
    }
}